import React, { useEffect, useState } from "react";
import {
    IonButton,
    IonRow,
    IonCol,
    IonRouterLink,
    IonIcon,
    IonText,
} from "@ionic/react";
import Axios from "axios";

import PageWrapper from "../layout/PageWrapper";
import useLoading from "../hooks/useLoading";
import Input from "../helpers/form/FormItem";
import {
    EmailElement,
    PasswordElement,
} from "../helpers/form/LoginPageElements";
import { changedHandler } from "../helpers/Validation";
import { GetFormValues, CheckValidation } from "../helpers/form/GetFormValues";

import { arrowForward, personAdd } from "ionicons/icons";
import "./FormPages.scss";
import SetPasswordShowIcon from "../helpers/form/SetPasswordShowIcon";
import { Capacitor } from "@capacitor/core";

interface Props {
    onLogin: (login: boolean, token: string) => void;
}

const LoginPage: React.FC<Props> = ({ onLogin }) => {
    const { addLoading, removeLoading } = useLoading();
    const [showPassword, setShowPassword] = useState(false);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    useEffect(() => {
        const TempInputData = [...inputData];
        TempInputData[1] = SetPasswordShowIcon(
            TempInputData[1],
            showPassword,
            setShowPassword
        );
        setInputData(TempInputData);
    }, [showPassword]);

    const tempEmailElement = { ...EmailElement };
    tempEmailElement.elementConfig.onKeyDown = handleKeyDown;
    const tempPasswordElement = { ...PasswordElement };
    tempPasswordElement.elementConfig.onKeyDown = handleKeyDown;
    const [inputData, setInputData] = useState([
        tempEmailElement,
        SetPasswordShowIcon(tempPasswordElement, showPassword, setShowPassword),
    ]);

    const handleLogin = async () => {
        addLoading();
        const ValidObj = CheckValidation(inputData);
        if (ValidObj["isValid"]) {
            let values = GetFormValues(inputData);
            Axios.post("/login", values)
                .then((res) => {
                    if (Capacitor.isNative) {
                        window.location.replace("/news");
                    }
                    onLogin(true, res.data.access_token);
                    removeLoading();
                })
                .catch((err) => {
                    removeLoading();
                });
        } else {
            setInputData(ValidObj["elements"]);
            removeLoading();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <PageWrapper gridClassName="authentication-page-grid">
            <h1 className="page-title">Bejelentkezés</h1>

            <p className="ion-color-dark">
                A kiválasztott művelethez jelentkezzen be az alábbi űrlap
                segítségével!
            </p>

            {new Date() < new Date("2025-01-17T12:00:00") && (
                <p>
                    <IonText color="danger">
                        <p>
                            <b>Kedves Ügyfelünk!</b>
                        </p>
                        <p>
                            Szeretnénk felhívni figyelmét, hogy adatbázis
                            karbantartás miatt online ügyfélszolgálatunk
                            2025.01.17-én pénteken, előreláthatóan 10:00 és
                            12:00 óra között nem lesz elérhető.{" "}
                        </p>
                        <p>
                            Telefonon és e-mailes ügyfélszolgálatunk ez idő
                            alatt is zavartalanul üzemel.
                        </p>
                        <p>Megértését és türelmét előre is köszönjük!</p>
                    </IonText>
                </p>
            )}

            <div className="login-form">
                <div className="lower-content">
                    <form className="form" onSubmit={handleSubmit}>
                        {inputData.map((element, index) => (
                            <Input
                                key={index}
                                formElement={element}
                                changed={(event) =>
                                    changedHandler(
                                        index.toString(),
                                        inputData,
                                        setInputData,
                                        event
                                    )
                                }
                            />
                        ))}

                        <IonRow className="ion-margin-top ion-margin-bottom">
                            <IonCol size="12" className="ion-text-left">
                                <IonRouterLink
                                    routerDirection="none"
                                    routerLink="/lost-password"
                                    className="auth-link"
                                >
                                    Elfelejtettem a jelszavam
                                </IonRouterLink>
                            </IonCol>
                        </IonRow>

                        <div className="ion-text-center">
                            <IonButton
                                id="loginButton"
                                size="large"
                                expand="block"
                                shape="round"
                                color="secondary"
                                className="large-btn"
                                onClick={handleLogin}
                            >
                                <IonIcon
                                    slot="start"
                                    icon={arrowForward}
                                ></IonIcon>
                                Bejelentkezés
                            </IonButton>
                            <br />
                            <p>Nincs még fiókja?</p>
                            <IonButton
                                size="large"
                                expand="block"
                                shape="round"
                                className="large-btn medium-btn"
                                routerDirection="none"
                                routerLink="/registration"
                            >
                                <IonIcon
                                    slot="start"
                                    icon={personAdd}
                                ></IonIcon>
                                Regisztráció
                            </IonButton>
                        </div>
                    </form>
                </div>
            </div>
        </PageWrapper>
    );
};

export default LoginPage;
