import React, { useEffect, useState } from "react";
import PageWrapper from "../layout/PageWrapper";
import { IonSkeletonText } from "@ionic/react";
import { useParams } from "react-router-dom";
import Axios from "axios";

import "./NewsDataPage.scss";

const NewsDataPage: React.FC = () => {
    const { id }: any = useParams();
    const [newsData, setNewsData] = useState({
        title: "",
        content: "",
        picture: "",
        date: "",
    });

    useEffect(() => {
        Axios.get(`/posts/show/${id}`)
            .then((res) => {
                let tempNewsData = { ...newsData };
                tempNewsData.title = res.data.post.cim;
                tempNewsData.content = res.data.post.cikk;
                tempNewsData.picture = res.data.featured_image;
                tempNewsData.date = res.data.post.gyartdatum;
                setNewsData(tempNewsData);
            })
            .catch((err) => {
                //removeLoading();
            });
    }, [id]);

    return (
        <PageWrapper>
            <div className="news-data-page">
                <div className="news-data-header">
                    {newsData.title ? (
                        <h1>{newsData.title}</h1>
                    ) : (
                        <h1>
                            <IonSkeletonText
                                animated
                                style={{ width: "175px", height: "31px" }}
                            />
                        </h1>
                    )}
                </div>

                {newsData.content ? (
                    <div
                        className="news-data-content"
                        dangerouslySetInnerHTML={{ __html: newsData.content }}
                    ></div>
                ) : (
                    <>
                        <br />
                        <IonSkeletonText
                            animated
                            style={{ width: "70%", height: "21px" }}
                        />
                        <IonSkeletonText
                            animated
                            style={{ width: "90%", height: "21px" }}
                        />
                        <IonSkeletonText
                            animated
                            style={{ width: "80%", height: "21px" }}
                        />
                        <IonSkeletonText
                            animated
                            style={{ width: "60%", height: "21px" }}
                        />
                        <IonSkeletonText
                            animated
                            style={{ width: "90%", height: "21px" }}
                        />
                    </>
                )}
            </div>
        </PageWrapper>
    );
};
export default NewsDataPage;
